import {readAllData, writeData, clearAllData} from './utility';

// get all Objects of a user
export const getObjects = async(uuid)=>{
    const objects = [];
    const allObjects = await readAllData('objects');
    allObjects.forEach((object)=>{
        if(object.user_uuid === uuid){
            objects.push(object);
        }
    });
    return objects;
}

//get task of photo id 
export const taskOfPhoto = async(id) =>{
    let obj;
    const tasks = await readAllData('tasks');
    tasks.forEach((task)=>{
        if(task.content.some(content => content.uuid === id)){
            task.content.forEach(content =>{
                if(content.uuid === id){
                    obj = {
                        task,
                        contentObj: {
                            label: content.label,
                            value: content.value
                        }
                    }
                }
            })
        }
    })
    return obj;
}

// get coordiantes of object
export const getObjectCoords = async(uuid) =>{
    let coords;
    const allObjects = await readAllData('objects');
    if(allObjects){
        allObjects.forEach((item)=>{
            if(item.uuid === uuid){
                coords = {
                    lon: item.geo_json.coordinates[0],
                    lat: item.geo_json.coordinates[1]
                }
            }
        });
    }
    return coords;
}

// get user info
export const getUser = async(uuid) =>{
    let currentUser;
    const users = await readAllData('user');
    if(users){
        users.forEach((user)=>{
            if(user.uuid === uuid){
                currentUser = user;
            }
        })
    }
    return currentUser;
}

// store street name for every object in indexedDB
export const storeStreetName = async(uuid, streetName) =>{
    const storeName = await writeData('locationName', {uuid, street: streetName});
    return storeName;
}

//search all activities and tasks of object and return an object with arrays of tasks and activities
export const getActivitiesAndTasks = async(id) =>{
    let filteredTasks = [];
    let filteredActivities = [];
    const activities = await readAllData('activities');
    const tasks = await readAllData('tasks');
    if(activities && tasks){
        filteredActivities = activities.filter((activity)=>{
            if(activity.object_uuid === id){
                return true;
            }else{
                return false;
            }
    
        });
        filteredActivities.forEach((activity) => {
            tasks.forEach((task)=>{
                if(activity.uuid === task.activity){
                    filteredTasks.push(task);
                }
            })
        })
        return {
            filteredTasks: filteredTasks,
            filteredActivities: filteredActivities
        }
        
    }else{
        return false;
    }            
}

//search all activities of object and return an object with array of activities
export const getActivities = async(id) =>{
    let filteredActivities = [];
    const activities = await readAllData('activities');
    if(activities){
        filteredActivities = activities.filter((activity)=>{
            if(activity.object_uuid === id){
                return true;
            }else{
                return false;
            }
    
        });
        return {
            filteredActivities: filteredActivities
        }
        
    }else{
        return false;
    }            
}

// get all info of an activity
export const getActivity = async(id) =>{
    let activityObject = {};
    const activities = await readAllData('activities');
    if(activities){
        activities.forEach((activity)=>{
            if(activity.uuid === id){
                activityObject = activity;
            }
        })
        return activityObject;
    }else{
        return false;
    }
}

// get all info of a task
export const getTask = async(id) =>{
    let taskObject = {};
    const tasks = await readAllData('tasks');
    if(tasks){
        tasks.forEach((task)=>{
            if(task.uuid === id){
                taskObject = task;
            }
        });
        return taskObject;
    }else{
        return false;
    }
}

// get object Id of the activity passed as parameter
export const getObjectIdOfActivity = async(activityId) =>{
    let objectId;
    const activities = await readAllData('activities');
    if(activities){
        activities.forEach(activity =>{
            if(activity.uuid === activityId){
                objectId = activity.object_uuid;
            }else{
                return false;
            }
        });
        return objectId;
    }else{
        return false;
    } 
}

// get all tasks of activity
export const getTasksOfActivity = async(activityId) =>{
    let tasksArray = [];
    const tasks = await readAllData('tasks');
    if(tasks){
        tasksArray = tasks.filter(task =>{
            if(task.activity === activityId){
                return true;
            }else{
                return false;
            }
        });
        return tasksArray;
    }else{
        return false;
    }
        
}

//get activity id of task
export const getActivityIdOfTask = async(taskId) =>{
    let activityId; 
    const tasks = await readAllData('tasks');
    if(tasks){
        tasks.forEach(task =>{
            if(task.uuid === taskId){
                activityId = task.activity;
            }
        });
        return activityId;
    }else{
        return false;
    }
}

//check if tasks are completed and return an object with total of completed and uncompleted tasks
export const checkTasksCompleted = (tasks) =>{
    let tasksCompletedCount = 0; 
    tasks.forEach((taskObject)=>{
        if(taskObject.state === "completed" || taskObject.state === "pending_approval"){
            tasksCompletedCount++
        }
    })
    return {
        tasksUncompleted: tasks.length - tasksCompletedCount,
        tasksCompleted: tasksCompletedCount
    }
}

//check if activities is completed
export const checkActivityCompleted = async(activityId) =>{
    let completed;
    // get tasks of activity
    const tasks = await getTasksOfActivity(activityId);
    //check status of tasks 
    const tasksCompleted = checkTasksCompleted(tasks);
    if(tasksCompleted.tasksUncompleted === 0){
        completed = true;
    }else{
        completed = false;
    }
    return completed;
}

// get street name of object
export const getStreetName = async(id) =>{
    let streetName;
    let locations = await readAllData('locationName');
    if(locations){
        locations.forEach(location =>{
            if(location.uuid === id){
                streetName = location.street
            }
        });
        return streetName;
    }
}

// get name of object
export const getObjectName = async(id) => {
    let objectname = '';
    const label = 'No Label ';
    const objects = await readAllData('objects');
    if(objects){
        objects.forEach(object =>{
            if(object.uuid === id){
                if(object.label !== "" && object.label !== null){
                    objectname = object.label
                }else{
                    switch(object.type){
                        case 'fc_manhole':
                            objectname = label + 'Chamber';
                            break;
                        case 'fc_pole':
                            objectname = label + 'Pole';
                            break;
                        case 'fc_building':
                            objectname = label + 'Building';
                            break;
                        case 'fc_closure':
                            objectname = label + 'Closure';
                            break;
                        case 'fc_cabinet':
                            objectname = label + 'Cabinet';
                            break;   
                        case 'fc_annotation_point':
                            objectname = label + 'Annotation Point';
                            break;
                        case 'fc_techsite':
                            objectname = label + 'Tech Site';
                            break;
                        case 'fc_toby_box':
                            objectname = label + 'Toby Box';
                            break;
                        case 'fc_pathway':
                            objectname = label + 'Pathway';
                            break;
                        case 'fc_cable':
                            objectname = label + 'Cable';
                            break;
                        case 'fc_annotation_zone':
                            objectname = label + 'Zone';
                            break;
                        case 'fc_optical_line_termination':
                            objectname = label + 'Central Office';
                            break;
                        case 'fc_technical_local_olt':
                            objectname = label + 'Local Central Office';
                            break;
                        case 'fc_site':
                            objectname = label + 'Tower';
                            break;
                        case 'fc_technical_local_site':
                            objectname = label + 'Local Tower';
                            break;
                        case 'fc_slack':
                            objectname = label + 'Slack';
                            break;
                        case 'fc_fiber_distribution_point':
                            objectname = label + 'Distribution Point';
                            break;
                        case 'fc_technical_local_fdp':
                            objectname = label + 'Local Distribution Point';
                            break;
                        default:
                            objectname = label + 'Object';
                            break;
                    }
                }
            }
        })
        return objectname;
    }else{
        return false;
    }
}

//get current user id
export const getCurrentUser = async() =>{
    const user = await readAllData('currentUser');
    //if array received, return array, if undefined received, send false
    if(user){
        return user;
    }else{
        return false;
    }
}

//save user on DB
export const saveUser = async(user) =>{
    //if user inserted in DB send true
    const res = await writeData('user',user)
    return res;
}

//get all users
export const getUsers = async() =>{
    const users = await readAllData('user');
    return users;
}

//save current user
export const saveCurrentUser = async(uuid, object)=>{
    await clearAllData('currentUser');
    const urlObject = {
        uuid,
        auth: process.env.VUE_APP_URL_AUTH,
        url: process.env.VUE_APP_URL_API
    };
    //if user inserted in DB send true
    const res = await writeData('currentUser', urlObject);
    return res;
    
}

//check status of task and return a string with css class name
export const checkTaskStatus = (task) =>{
    if(task.state === "scheduled" || task.state === "created" || task.state === "reopened"){
        return 'redStatus';
    }else if (task.state === "in_progress"){
        return 'yellowStatus';
    }else{
        return 'greenStatus';
    }
}

// get token of user id
export const getToken = async(userId) =>{
    let token;
    const users = await readAllData('user');
    users.forEach(user =>{
        if(user.uuid === userId){
            token = user.token;
        }
    })
    return token;
}

// save timestamp on DB
export const saveTimestamp = async(userUuid, timestamp) =>{
    await writeData('timestamp', {id:userUuid, timestamp});
}

//get timeStamp of user 
export const getTimestamp = async(uuid) =>{
    let timestamp;
    const alltimes = await readAllData('timestamp');
    alltimes.forEach(time =>{
        if(time.id === uuid){
            timestamp = time.timestamp;
        }
    })
    return timestamp;
}

//send submits made offline
export const getTaskToUpdate = async() =>{
    const tasks = await readAllData('updateLocations');
    return tasks;
}

// update object
export const updateObject = async(object) =>{
    if(object){
        await writeData('objects',object);
    }
}

//update activity
export const updateActivity = async(activity) =>{
    if(activity){
        await writeData('activities',activity);
    }
}

// update task
export const updateTask = async(task) =>{
    if(task){
        await writeData('tasks',task);
    }
}

// get class name of marker
export const getMapObjectName = async(type, activities) => {
    let activitiesUncompleted = 0;
    for(let i = 0; i < activities.length; i++) {
        //get activity status
        const completed = await checkActivityCompleted(activities[i].uuid);
        // if completed === false, increment umcompleted activities value by 1
        if(!completed){
            activitiesUncompleted++
        }
    }
    let name;   
    switch(type){
        case 'fc_manhole':
            name = 'manhole';
            break;
        case 'fc_pole':
            name = 'pole';
            break;
        case 'fc_building':
            name = 'house';
            break;
        case 'fc_closure':
            name = 'closure';
            break;
        case 'fc_cabinet':
            name = 'cabinet';
            break;   
        case 'fc_annotation_point':
            name = 'annotation_point';
            break;
        case 'fc_techsite':
            name = 'techsite_2';
            break;
        case 'fc_toby_box':
            name = 'toby_box';
            break;
        case 'fc_optical_line_termination':
            name = 'central_office';
            break;
        case 'fc_site':
            name = 'tower';
            break;
        case 'fc_technical_local_site':
            name = 'tower';
            break;
        case 'fc_slack':
            name = 'slack';
            break;
        case 'fc_fiber_distribution_point':
            name = 'fdp';
            break;
        case 'fc_technical_local_fdp':
            name = 'fdp';
            break;
        default:
            objectname = label + 'Object';
            break;
    }
    
    // if no task uncompleted, add 'finished' to class name
    if(activitiesUncompleted === 0){
        return name + '_finished';
    }else{
        return name;
    }
}

// check if object exists on array, return true if exits, else return false
export const checkTaskExists = (array, uuid) =>{
    return array.some(element => element.uuid === uuid);
}
