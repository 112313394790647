<template>
  <div @submit.prevent="submit" class="loginContainer">
    <div class="loginImageContainer">
      <img class="loginImage" src="../assets/login.svg" alt="weezie logo"/>
    </div>
    <div class="loginTitleContainer">
      <h2>Login</h2>
      <span>Please sign in to continue</span>
    </div>
    <form class="loginForm">
      <div class="loginFormField">
        <div class="loginInputField">
          <span class="loginFieldTag">USERNAME</span>
        </div>
        <label for="username" class="fa-solid fa-user loginInputIcon"/>
        <input type="text" id="username" v-model="username" required/>
      </div>
      <div class="loginFormField">
        <div class="loginInputField">
          <span class="loginFieldTag">PASSWORD</span>
        </div>
        <label for="password" class="fa-solid fa-lock loginInputIcon"/>
        <input type="password" id="password" v-model="password" required/>
      </div>
      <div class="loginErrorContainer">
        <p>{{ error }}</p>
      </div>
      <div class="loginBtnContainer">
        <button class="loginBtn" type="submit">LOGIN</button>
      </div>
    </form>
  </div>
</template>

<script>
const bcrypt = require('bcryptjs');
import {ref} from "vue";
import router from "../router/index";
import {getUsers, saveUser, saveCurrentUser} from '@/utility/utils';

export default {
  name: 'LoginView',
  data() {
    return {
      username: null,
      password: null,
      error: null,
      user: null
    }
  },

  methods: {
    submit: async function () {
      console.log(process.env)
      let isAuth = false;
      let userExists = false;
      let refresh_token = '';
      //get all users in DB
      const users = await getUsers();
      //if users were received from DB, do login
      if (users !== false) {
        //if no network detected
        if (!window.navigator.onLine) {
          //check if credentials inserted are valid
          users.forEach(user => {
            if ((user.imei.toString() === this.username) && bcrypt.compareSync(this.password, user.password)) {
              isAuth = true;
            }
          });
          //if credentials are valid, sendo to homeView, if not, display error message
          if (isAuth) {
            router.push({name: 'home'});
          } else {
            this.showError('Invalid Credentials')
          }
        } else {
          //network detected
          //if Db has users check credentials, else send user whitout refresh_token
          if (users.length > 0) {
            //if user already exits on DB send user whit refresh_token, else send user whitout refresh_token
            users.forEach(user => {
              if (user.imei.toString() === this.username) {
                userExists = true;
                refresh_token = user.refresh_token
              }
            });
            if (userExists) {
              this.user = {
                imei: this.username,
                password: this.password,
                refresh_token
              }
              await this.login(this.user);
            } else {
              this.user = {
                imei: this.username,
                password: this.password
              }
              await this.login(this.user);
            }
          } else {
            this.user = {
              imei: this.username,
              password: this.password
            }
            await this.login(this.user);
          }
        }
      }
    },
    login: async function(user)  {
      //hash password inserted by user to store it in DB

      // fetch request to authenticate user
      try {
        const res = await fetch(`${process.env.VUE_APP_URL_AUTH}auth`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'User-Agent': 'Tasks'
          },
          body: JSON.stringify(user)
        });
        let result = null;

        //if not authenticated, display error message
        switch (res.status){
          case 401:
            this.showError('Invalid Credentials');
            break;
          case 200:
            result = await res.json();
            await this.getUserData(result);
            break;
          default:
            this.showError('Something went wrong');
        }
      } catch (err) {
        this.showError('Something went wrong');
        console.log(err);
      }
    },
    showError: function(message){
      this.error = message;
      setTimeout(() => {
        this.error = null
      }, 3000);
    },
    getUserData: async function(data){
      let salt = bcrypt.genSaltSync(10);
      let hashedPassword = bcrypt.hashSync(this.user.password, salt);

      const getUser = await fetch(`${process.env.VUE_APP_URL_AUTH}mydetails`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          "Authorization": `Bearer ${data.token}`
        }
      });
      if (getUser.status === 200) {
        const userData = await getUser.json();
        // user data to be stored in DB
        const hashedUser = {
          uuid: userData.data.uuid,
          imei: userData.data.imei,
          password: hashedPassword,
          ...data
        };
        //store uuid of current user
        const saveCurrent = await saveCurrentUser(userData.data.uuid);
        //if user stored succesfully, send to homeView, else, display error message
        const savedUser = await saveUser(hashedUser);
        //save timestamp of user

        if (savedUser && saveCurrent) {
          router.push({name: 'home'});
        } else {
          this.showError('Something went wrong')
        }
      } else {
        this.showError('Something went wrong')
      }
    }
  }
}

</script>

<style>
.loginContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
}

.loginImageContainer {
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginImage {
  height: 100%;
  width: 50%;
  font-size: 20px;
}

.loginErrorContainer {
  color: red;
  margin-top: 12px;
  font-size: 13px;
}

.loginTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 80px;
  margin-bottom: 15px;
  font-weight: 700;
}

.loginTitleContainer span {
  color: #989898;
}

.loginTitleContainer span {
  font-weight: bold;
}

.loginForm {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.loginInputField {
  margin-bottom: 10px;
  font-weight: bold;
}

.loginFieldTag {
  padding-left: 38px;
  margin-bottom: 10px;
  font-size: 10px;
  color: #FF8D2F;
}

.loginFormField {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.loginFormField input {
  border-radius: 5px;
  width: 100%;
  height: 6vh;
  outline: none;
  border: none;
  padding-left: 38px;
  padding-bottom: 5px;
  border-bottom: 7px solid #989898;
  font-size: 17px;
}

.loginFormField input:focus {
  border-bottom: 2px solid #989898;
}

.loginFormField:focus-within span {
  color: #989898;
}

.loginInputIcon {
  position: absolute;
  font-size: 20px;
  left: 17px;
  top: 50%;
}

.loginBtnContainer {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: flex-end;
}

.loginBtn {
  margin-top: 5px;
  border: none;
  border-radius: 18px;
  width: 45%;
  height: 8vh;
  background-color: #FF8D2F;
  color: white;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}

@media (min-width: 320px) {
  .loginContainer {
    align-items: start;
    padding: 0px 18px;
  }

  .loginForm {
    width: 100%;
  }

}

@media (min-width: 768px) {
  .loginBtn {
    width: 35%;
  }
}
</style>