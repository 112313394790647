<template>
    <div class="infoViewContainer">
        <div class="infoNavbar">
            <div @click="goToHome" class="infoImgContainer">
                <img src="../assets/arrow.png" alt="arrow icon"/>
            </div>
            <div class="infoNameContainer">
                <h3>Object Details</h3>
            </div>
        </div>
        <div class="objectIdContainer">
            <div class="objectIdInfoContainer">
                <p>Object UUID</p>
                <span>{{params.id}}</span>
            </div>
            <div @click="clipboard" class="objectIdImgContainer">
                <img src="../assets/clickboard.png" alt="clickboard icon"/>
            </div>
        </div>
        <div class="objectInfoContainer">
            <div class="infoContainer">
                <p>Object Info</p>
            </div>
            <div class="remainingContainer">
                <div v-if="activitiesUncompleted !== 0" class="remainingWrapper">
                    <p>{{activitiesUncompleted}} ACTIVITIES REMAINING</p>
                </div>
                <div v-if="activitiesUncompleted === 0" class="remainingWrapper green">
                    <p>All ACTIVITIES COMPLETED</p>
                </div>
            </div>
            <div class="objectStreetContainer">
                <div class="objectStreetWrapper">
                    <div class="streetInfoContainer">
                        <div class="streetInfoWrapper">
                            <h3>{{objectName}}</h3>
                            <div class="streetContainer">
                                <img src="../assets/marker.png" alt="marker"/>
                                <p>{{streetName}}</p>
                            </div>
                        </div>
                    </div>
                    <MapButton infoView="infoView" :objectId = "params.id"/>
                </div>
            </div>
            <div class="activityInfoWrapper">
                <div @click="goToUpdate(activity.uuid)" class="activityInfoContainer" v-for="activity in activityWithTasks" :key="activity.uuid">
                    <div class="activityDateContainer">
                        <div class="activityDateWrapper">
                            <h4>{{activity.title}}</h4>
                            <p><img src="../assets/calendar.png" alt="calendar icon"/>{{activity.date}}</p>
                        </div>
                        <div class="activityDateWrapperImg">
                            <img v-if="activity.completedTasks.tasksUncompleted !== 0" src="../assets/redCheck.png" alt="green check icon"/>
                            <img v-if="activity.completedTasks.tasksUncompleted === 0" src="../assets/check.png" alt="green check icon"/>
                        </div>
                    </div>
                    <p><img class="tasksIcon" src="../assets/tasksCompleted.png" alt="green check icon"/>{{activity.completedTasks.tasksCompleted}} tasks completed</p>
                    <p><img class="tasksIcon" src="../assets/tasksUncompleted.png" alt="red check icon"/>{{activity.completedTasks.tasksUncompleted}} tasks to be completed</p>
                </div>
            </div>
        </div>
        <div v-if="showModal" class="alertContainer">
            <div class="alertMsgContainer">
                <p>Copied to clipboard</p>
            </div>
        </div>
    </div>
</template>

<script>
import {ref} from 'vue';
import { useRoute } from "vue-router";
import router from "../router/index";
import {getActivitiesAndTasks, checkTasksCompleted, checkActivityCompleted, getStreetName, getTasksOfActivity, getObjectName} from '../utility/utils';
import MapButton from '../components/MapButton.vue';
export default {
    name: 'InfoView',
    components:{
        MapButton
    },
    props:{
        id: {
            type: String
        }
    },
    setup(){
        const route =useRoute();
        const activitiesUncompleted = ref(0);
        let activityWithTasks = ref([]);
        const streetName = ref(null);
        const objectName = ref(null);
        const params = route.params;
        const objectId = params.id;
        const showModal = ref(false);

        //get tasks, activities and street name of object
        const getData= async()=>{
            //get street name of object
            const street = await getStreetName(params.id);
            if(street){
                streetName.value = street;
            }

            //get object name
            const label = await getObjectName(params.id);
            if(label){
                objectName.value = label;
            }
            
            //get activities and tasks
            const activitiesAndTasks = await getActivitiesAndTasks(params.id);
            if(activitiesAndTasks){
                //create activities arrray
                const activities = activitiesAndTasks.filteredActivities;

                //loop activities to get all task of an activity
                for(let i=0; i < activities.length; i++){
                    //get activity status
                    const completed = await checkActivityCompleted(activities[i].uuid);
                    // if completed === false, increment umcompleted activities value by 1
                    if(!completed){
                        activitiesUncompleted.value++
                    }
                    //create tasks array of activity
                    const tasksArray = await getTasksOfActivity(activities[i].uuid);
                    if(tasksArray){
                        //get completed and uncompleted tasks
                        const completedTasks = checkTasksCompleted(tasksArray);
                        //create object with activity id and all task of that activity
                        const activityWithTasksStatusObject = {
                            uuid: activities[i].uuid,
                            date: activities[i].due_date === null ? 'No date Provided' : activities[i].due_date,
                            title: activities[i].title, 
                            completedTasks
                        }; 
                        // push activity object into array
                        activityWithTasks.value.push(activityWithTasksStatusObject);
                    }
                }
                
            }

        }
        getData();

        //send to homeView
        const goToHome = () =>{
            router.push({name: 'home'});
        }
        //send to UpdateView
        const goToUpdate = (id) =>{
            router.push({ name: 'update', params: { id } })
        }

        //copy to clipboard function
        const clipboard = () =>{
            //copy to clipboard
            navigator.clipboard.writeText(params.id);
            // show modal
            showModal.value = true;
            //hide modal after 2.5 sg
            setTimeout(() => {showModal.value = false}, 2500);
        }

        return{activitiesUncompleted, streetName, objectId ,params, activityWithTasks, goToUpdate, goToHome, objectName, clipboard, showModal}
        
    }
}
</script>

<style>
.infoViewContainer{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    background-color: #F4F5F4;
}
.infoNavbar{
    display: flex;
    width: 100%;
    height: 70px;
    background-color: #FF8D2F;
    position: sticky;
    top: 0;
}
.infoImgContainer{
    top: calc(35px - 10px);
    left: 25px;
    height: 20px;
    width: 28px;
    cursor: pointer;
    position: absolute;
}
.infoImgContainer img {
    height: 100%;
    width: 100%;
}
.infoNameContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.objectIdContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    font-size: 14px;
    padding: 14px 14px 14px 25px;
}
.objectIdContainer p{
    color: #989898;
    font-size: 16px;
}
.objectIdInfoContainer{
    width: calc(100% - 30px);
    height: 100%;
}
.objectIdImgContainer{
    width: 25px;
    height: 30px;
    margin-left: 5px;
    cursor: pointer;
}
.objectIdImgContainer img {
    width: 100%;
    height: 100%;
}

.objectInfoContainer{
    width: 100%;
}
.infoContainer{
    padding-top: 10px;
}
.infoContainer p{
    font-size: 18px;
    padding-left: 25px;
}

.remainingContainer{
    margin: 18px 0px;
    width: 100%;
}
.remainingWrapper{
    padding: 5px 0px;
    background-color: #F6C9D5;
    color: #D8696E;
    margin-left: 24px;
    width: 155px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    font-size: 10px;
}
.green{
    background-color: #C3F2DF;
    color: #6AAB78;
}
.objectStreetContainer{
    padding: 0px 25px;
    width: 100%;
}
.objectStreetWrapper{
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
    border-bottom: 2px solid #CCCBCB;
}
.streetInfoContainer{
    width: 80%;
    display: flex;
    align-items: center;
}
.streetInfoContainer h3{
    margin-bottom: 5px;
}
.streetInfoContainer p {
    color: #989898;
    font-size: 16px;
}
.streetInfoContainer img{
    height: 20px;
    padding-right: 5px;
}
.streetContainer{
    display: flex;
    align-items: center;
}
.activityInfoWrapper{
    margin-top: 20px;
    width: 100%;
    padding: 0px 25px;
}
.activityInfoContainer{
    padding: 10px 10px;
    width: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 2px 5px #989898;
    margin-bottom: 12px;
    cursor: pointer;
}
.activityInfoContainer p{
    font-size: 14px;
}
.activityDateContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;
    width: 100%;
}
.activityDateWrapper{
    width: calc(80% - 28px);
    overflow-wrap: break-word;
}

.activityDateWrapperImg{
    align-self: flex-start;
}
.activityDateWrapperImg img {
    height: 22px;
}
.activityDateWrapper h4{
    font-size: 18px;
}
.activityDateWrapper p{
    color: #989898;
}
.activityDateWrapper img{
    height: 14px;
    padding-right: 4px;
}
.tasksIcon{
    height: 12px !important;
    padding-right: 4px;
}
.infoMapBtnContainer{
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.infoMapBtnWrapper{
    border-radius: 10px;
    background-color: #FF8D2F;
    width: 45px;
    height: 45px;
    padding: 10px;
}
.infoMapBtnContainer img {
    height: 100%;
    width: 100%;
}
.alertContainer{
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.alertMsgContainer{
    color: white;
    width: 50%;
    height: 100%;
    background-color: rgb(92, 92, 211);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alertMsgContainer p {
    font-size: 13px;
}
@media only screen and (max-width: 320px){
    .objectIdContainer {
        font-size: 12px;
    }
}

@media only screen and (min-width: 375px){
    .objectIdContainer {
        padding-right: 25px;
    }
}

@media only screen and (min-width: 768px){
    .infoImgContainer{
        left: 70px;
    }
    .objectIdContainer{
        padding: 14px 70px 14px 70px;
    }
    .objectInfoContainer{
        padding-left: 45px;
        padding-right: 45px;
    }
}
</style>