<template>
  <div class="homeContainer">
    <div id="map" class="homeMap"/>
    <div class="syncButtonContainer">
        <div @click="sync" class="syncButtonWrapper">
          <i class="fa-solid fa-arrows-rotate"></i>
        </div>
    </div>
    <NavigationBar v-if="!showModal" component="home"/>
    <Carousel v-if="showModal">
      <Slide v-for="location in filteredLocations" :key="location.uuid">
        <LocationInfo :object="location"/>
      </Slide>
      <template #addons>
        <Navigation/>
      </template>
    </Carousel>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import {onMounted, ref} from 'vue';
import LocationInfo from '../components/LocationInfo';
import NavigationBar from '../components/NavigationBar';
import { Carousel, Navigation, Slide} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import {deleteItem} from '../utility/utility'; 
import {getCurrentUser, getMapObjectName, getObjects, getActivities, getUser, getTaskToUpdate, saveTimestamp, getTimestamp} from '../utility/utils';
import router from '../router';
export default {
  name: 'HomeView',
  components: {
    Carousel,
    Navigation,
    LocationInfo,
    Slide,
    NavigationBar
  },
  setup(){
    let map;
    let filteredLocations = ref(null);
    let showModal = ref(false);
    let locations = ref(null);
    let networkDataReceived = ref(false);
    let user;
    let timestamp;
    let longitude; 
    let latitude;
    let currentUser;
    //get user location and pass it on mountMap Function
    onMounted(()=>{
      navigator.geolocation.getCurrentPosition(mountMap,getError);
    });
    
    //mount map whit users current location
    const mountMap=(pos)=>{
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;
      map = new mapboxgl.Map({
        container: 'map', // container ID
        style: {
          version: 8,
          sources: {},
          layers: []
        },
        center: [pos.coords.longitude,pos.coords.latitude], // starting position [lng, lat]
        zoom: 11 // starting zoom
      });
      latitude = pos.coords.latitude;
      longitude = pos.coords.longitude;
      //adding style
      map.setStyle("mapbox://styles/mapbox/streets-v8"); 
      //Add geolocate controls
      addControl();
      //get locations
      getLocations();
      
      //click event on map to get locations near click event
      map.on('click',(e)=>{
        if(locations.value){
          //array with locations near click event on map
          const filteredArrayNear = locations.value.filter(location=>{

            let locationCoors = {
              lng: location.geo_json.coordinates[0],
              lat: location.geo_json.coordinates[1]
            };
            return arePointsNear(locationCoors,e.lngLat,0.2)

          });
          //pass filtered location near click event on filteredLocations array;
          filteredLocations.value = filteredArrayNear;
          if(showModal.value === true){
            showModal.value = false;
          }else{
            if(filteredLocations.value.length !== 0){
              showModal.value = true;
            }
          }
        }
      });
    }

    // Add geolocate control to map.
    const addControl=()=>{
      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          // When active the map will receive updates to the device's location as it changes.
          trackUserLocation: true,
          // Draw an arrow next to the location dot to indicate which direction the device is heading.
          showUserHeading: true
        })  
      );
    }

    //get locations and create markers on map
    const getLocations = async() =>{
      let objects;
      //get uuid of current user
      currentUser = await getCurrentUser();
      if(currentUser.length > 0){
        user = await getUser(currentUser[0].uuid);
        if(user){
          timestamp = await getTimestamp(user.uuid);
          if(timestamp){
            // sending timestamp different from 0
            await fetchLocations(user.token, timestamp);
            objects = await getObjects(user.uuid);
            locations.value = objects;
            createMarkers();
            
          }else{
            objects = await getObjects(user.uuid);
            // sending timestamp 0
            await fetchLocationsNoTimestamp(user.token, "0");
            if(!networkDataReceived.value){
              //if exits data on indexedDB, create markers
              locations.value = objects;
              //create markers
              createMarkers();
            }
          }
        }
      }
      
    }

    const fetchLocations= async(token, timestamp)=>{
      try{
        let boolean = false;
        const response = await fetch(`${currentUser[0].url}objects/myactivities?deleted=${boolean}`,{
          method: "GET",
          headers:{
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
            "timestamp": timestamp,
            "lat": latitude,
            "lon": longitude
          }
        });
        if(response.status !== 200){
          router.push('login');
        }else{
          const res = await response.json();
          console.log(res);
          //save timestamp
          await saveTimestamp(user.uuid, res.timestamp_server);
        }
      }catch(err){
        console.log(err);
      }
    }

    // fetch all objects activities and tasks with timestamp iquals 0
    const fetchLocationsNoTimestamp = async(token, timestamp)=>{
      try{
        let boolean = false;
        const response = await fetch(`${currentUser[0].url}objects/myactivities?deleted=${boolean}`,{
          method: "GET",
          headers:{
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
            "timestamp": timestamp,
            "lat": latitude,
            "lon": longitude
          }
        });
        if(response.status !== 200){
          router.push({name: 'login'});
        }else{
          networkDataReceived.value = true;
          const res = await response.json();
          console.log(res);
          await saveTimestamp(user.uuid, res.timestamp_server);
          locations.value = res.data.objects;
          //create markers
          createMarkers();
        }
      }catch(err){
        console.log(err);
      }
    }

    const getError=(err)=>{
      console.log(err.status);
      console.log(err);
    }
    
    //function to check if 2 points are between bounds of distance passed - return boolean values
    const arePointsNear=(checkPoint, centerPoint, km)=>{
      var ky = 40000 / 360;
      var kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
      var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
      var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
      return Math.sqrt(dx * dx + dy * dy) <= km;
    }

    //create markers in map
    const createMarkers= async()=>{
      const store = {
          features: 
            locations.value.map((location)=>{
              return {
                uuid: location.uuid,
                type: location.type,
                lon: location.geo_json.coordinates[0],
                lat: location.geo_json.coordinates[1]
              }
            })
      };
      store.features.map(async(loc) =>{
        //console.log(store.features[i]);
        // get activities of object
        const activities = await getActivities(loc.uuid);
        // create div for marker
        const el = document.createElement('div');
        // get class name of marker
        const name = await getMapObjectName(loc.type, activities.filteredActivities);
        el.className = name;
        //create markers
        const marker = new mapboxgl.Marker(el).setLngLat({lon: loc.lon, lat: loc.lat}).addTo(map);
        
        // use GetElement to get HTML Element from marker and add click event
        marker.getElement().addEventListener('click',(e)=>{
          showModal.value = !showModal.value;
          // Store the marker's longitude and latitude coordinates in a variable
          const lonLat = marker.getLngLat();
          //console.log(`Longitude: ${lonLat.lng}, Latitude: ${lonLat.lat}`);
          
          //filter locations array to create a new array with data of marker clicked
          const filteredArray = locations.value.filter(location=>{
            if((location.geo_json.coordinates[0] === lonLat.lng && location.geo_json.coordinates[1] === lonLat.lat)){
              return true;
            }else{
              return false
            }
          });
          filteredLocations.value = filteredArray;
          //avoid double event click when marker is clicked
          e.stopPropagation();
        });
      })
    }
    const sync = async()=>{
      let checklistArray = [];
      let textArray = [];
      let photoArray = [];

      if(window.navigator.onLine){
        // get tasks from DB
        const tasks = await getTaskToUpdate();
        // if db has task, execute fetchs, else, re evalute component
        if(tasks.length > 0){
          // create array for each task type
          tasks.forEach(task =>{
            switch(task.type){
              case 'checklist':
                checklistArray.push(task);
                break;
              case 'text':
                textArray.push(task);
                break;
              case 'photo': 
                photoArray.push(task);
                break;
              default: 
                break;
            }
          });
          //fetch every checklist task
          for(let i = 0; i < checklistArray.length; i++){
            await fetchCheckList(checklistArray[i]);
          }
          //fetch every text task
          for(let i = 0; i < textArray.length; i++){
            await fetchTextList(textArray[i]);
          }
          //fetch every photo task
          for(let i = 0; i < photoArray.length; i++){
            await fetchPhotoList(photoArray[i]);
          }
          // fetch new data
          getLocations();
        }else{
          // fetch new data
          getLocations();
        }
      }
    }

    const fetchCheckList = async(task) =>{
      try{
        // if content array has objects, execute fetch, else, delete task object from DB
        if(task.content.length > 0){
          const response = await fetch(`${currentUser[0].url}tasks/${task.uuid}/execute`,{
            method: 'PUT',
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${user.token}`,
            },
            body:JSON.stringify(task)
          });
          if(response.status === 200){
            const res = await response.json();
            console.log(res);
            // delete task in DB
            await deleteItem('updateLocations', task.uuid);
          }
        }else{
          await deleteItem('updateLocations', task.uuid);
        }
      }catch(err){
        console.log(err);
      }
    }

    const fetchTextList = async(task) =>{
      try{
        const restContent = [];
        //check for empty string in content value to be submitted
        task.content.forEach(item =>{
          if(item.value !== ''){
            restContent.push(item);
          }
        });
        task.content = restContent;
        if(task.content.length > 0){
          // PUT method
          const response = await fetch(`${currentUser[0].url}tasks/${task.uuid}/execute`,{
            method: 'PUT',
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${user.token}`,
            },
            body:JSON.stringify(task)
          });
          const res = await response.json();
          console.log(res);
          if(response.status === 200){
            //delete task from updateLocations table
            await deleteItem('updateLocations', task.uuid);
          }
        }else{
          //delete task from updateLocations table
          await deleteItem('updateLocations', task.uuid);
        }
      }catch(err){
        console.log(err);
      }
    }

    //submit photo task
    const fetchPhotoList = async(task) =>{
      //content whitout empty value
      const restContent = [];
      //check for empty string in content value to be submitted
      //create new content to submit
      task.content.forEach(item =>{
        if(item.value !== null){
          const obj = {
            label: item.label,
            options: item.options,
            uuid: item.uuid,
            value: item.value
          }
          restContent.push(obj);
        }
      });
      task.content = restContent;
      if(task.content.length > 0){
        // PUT method
        const response = await fetch(`${currentUser[0].url}tasks/${task.uuid}/execute`,{
          method: 'PUT',
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${user.token}`,
          },
          body:JSON.stringify(task)
        });
        const res = await response.json();
        console.log(res);
        if(response.status === 200){
          //delete task from updateLocations table
          await deleteItem('updateLocations', task.uuid);
        }
      }else{
        //delete task from updateLocations table
        await deleteItem('updateLocations', task.uuid);
      }
    }

    return {showModal, filteredLocations, sync};
  }
}

</script>
<style>
.homeContainer{
  width: 100%;
  position: relative;
}
.homeMap{
  height: 100vh;
  width:100%;
}

.carousel{
  height: 145px;
  width: 93%;
  position: absolute;
  bottom:10px;
  border-radius: 5px;
  left: 50%;
  transform: translate(-50%);
}
.carousel__viewport {
  height: 100%;
  width: 100%;
}
.carousel__track {
  height: 100%;
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;
}
.carousel__slide {
  height: 100%;
}
.carousel__next{
  display: none;
}
.carousel__prev{
  display: none;
}
.mapboxgl-ctrl-top-right{
  top: auto;
  bottom: 165px;
  right: 8px;
  height: 50px;
  width: 50px;
  padding: 5px 5px;
}
.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: none;
}
.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: 0;
  float: none;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #FF8D2F;
}
.mapboxgl-ctrl-group button{
  background-color: #FF8D2F;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  background-image: url(/src/assets/location.png);
  background-size: 20px
}
.syncButtonContainer{
  padding: 5px 5px;
  height: 50px;
  width: 50px;
  position: absolute;
  bottom: 215px;
  right: 8px;
}
.syncButtonWrapper{
  height: 100%;
  width: 40px;
  background-color: #FF8D2F;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.syncButtonWrapper i {
  color:white;
}
.mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-bottom-left{
  display: none;
}

.annotation_point {
  background-image: url(/src/assets/annotation_point.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.annotation_point_finished {
  background-image: url(/src/assets/annotation_point_finished.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.cabinet {
  background-image: url(/src/assets/cabinet.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.cabinet_finished {
  background-image: url(/src/assets/cabinet_finished.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.central_office {
  background-image: url(/src/assets/central_office.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.central_office_finished {
  background-image: url(/src/assets/central_office_finished.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.closure {
  background-image: url(/src/assets/closure.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.closure_finished {
  background-image: url(/src/assets/closure_finished.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.fdp {
  background-image: url(/src/assets/fdp.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.house {
  background-image: url(/src/assets/house.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.house_finished {
  background-image: url(/src/assets/house_finished.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.manhole {
  background-image: url(/src/assets/manhole.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.manhole_finished {
  background-image: url(/src/assets/manhole_finished.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.pole {
  background-image: url(/src/assets/pole.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.pole_finished {
  background-image: url(/src/assets/pole_finished.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.slack {
  background-image: url(/src/assets/slack.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.slack_finished {
  background-image: url(/src/assets/slack_finished.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.techsite_2 {
  background-image: url(/src/assets/techsite_2.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.techsite_2_finished {
  background-image: url(/src/assets/techsite_2_finished.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.toby_box {
  background-image: url(/src/assets/toby_box.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.toby_box_finished {
  background-image: url(/src/assets/toby_box_finished.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.tower {
  background-image: url(/src/assets/tower.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.tower_finished {
  background-image: url(/src/assets/tower_finished.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
@media only screen and (min-width: 768px){
  .carousel {
    height: 150px;
    width: 65%;
  }
}
@media only screen and (min-width: 1024px){
  .carousel {
    height: 170px;
    width: 55%;
  }
}
</style>


