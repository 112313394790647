import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker';


import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

Bugsnag.start({
    apiKey: '6762b9b4471681277f98980d23bb947d',
    plugins: [new BugsnagPluginVue()]
})

const bugsnagVue = Bugsnag.getPlugin('vue')

createApp(App).use(router).use(bugsnagVue).mount('#app')
