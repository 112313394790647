<template>
    <div class="photoViewContainer">
        <div class="infoNavbar">
            <div @click="goToUpdate(activityId)" class="infoImgContainer">
                <img src="../assets/arrow.png" alt="arrow icon"/>
            </div>
            <div class="infoNameContainer">
                <h3>{{objTitle}}</h3>
            </div>
        </div>
        <div class="viewContainer">
            <div class="photoViewWrapper">
                <h3>{{title}}</h3>
                <p>{{label}}</p>
                <div v-if="!photo" class="viewPhotoInput">
                    <span class="viewHorizontal"/>
                    <span class="viewVertical"/>
                </div>
                <div v-if="photo" class="viewPhotoContainer">
                      <img class="photo" :src="photo" alt="">
                    </div>
                <button @click="deletePhoto(taskId)" class="viewPhotoBtn">Delete</button>
            </div>
        </div>
    </div>
</template>

<script>
import {ref} from 'vue';
import { useRoute } from "vue-router";
import router from "../router/index";
import {taskOfPhoto, getObjectIdOfActivity, getObjectName, getTaskToUpdate, updateTask} from '../utility/utils';
import {writeData} from '../utility/utility';
export default {
    name: 'PhotoView',
    components:{
    },
    props:{
        id: {
            type: String
        }
    },
    setup(){
        const route = useRoute();
        const params = route.params;
        const photoId = params.id;
        const objTitle = ref(null);
        const title = ref(null);
        const label = ref(null);
        const photo = ref(null);
        const activityId = ref(null);
        const taskId = ref(null);
        let task;

        const getData = async() =>{
            const data = await taskOfPhoto(photoId);
            label.value = data.contentObj.label;
            photo.value = data.contentObj.value;
            title.value = data.task.title;
            activityId.value = data.task.activity;
            task = data.task;
            const objId = await getObjectIdOfActivity(data.task.activity);
            objTitle.value = await getObjectName(objId);

        }
        getData();

        //delete photo of DB
        const deletePhoto = async(taskId) =>{
            // update task of updateLocations table
            await updatePhotoToSubmit(taskId);
            // update task of tasks table
            await updatePhotoToSave(taskId);
        }

        //update task of updateLocations
        const updatePhotoToSubmit = async(taskId) =>{
            //update content to be submited
            let taskObj;
            const tasksArray = await getTaskToUpdate();
            tasksArray.forEach(task =>{
                if(task.uuid === taskId){
                    taskObj = {...task}
                }
            });
            if(taskObj){
                // update content to be submitted
                taskObj.content.forEach(item =>{
                    if(item.uuid === photoId){
                        item.value = null;
                    }
                });
                if(taskObj.copyTask){
                    //update content to be saved in tasks table when submit
                    taskObj.copyTask.content.forEach(item =>{
                        if(item.uuid === photoId){
                            item.value = null;
                        }
                    })
                }
                //save task in updateLocations
                await writeData('updateLocations',taskObj);
            }
        }

        //update task of tasks table
        const updatePhotoToSave = async() =>{
            task.content.forEach(item =>{
                if(item.uuid === photoId && item.value){
                    item.value = null;
                }
            })
            //save task on tasks table in DB
            await updateTask(task);
            getData();
        }

        //send to UpdateView
        const goToUpdate = (id) =>{
            router.push({ name: 'update', params: { id } });
        }

        return {goToUpdate, deletePhoto, objTitle, title, label, photo, activityId, taskId}
    }
}
</script>

<style>
.photoViewContainer{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: Montserrat,sans-serif;
    background-color: #f4f5f4;
}
.viewContainer{
    width: 100%;
    padding: 20px 30px;
    display: flex;
    justify-content: center;
}
.photoViewWrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    padding: 20px 30px;
}
.viewPhotoInput{
    position: relative;
    margin-top: 8px;
    width: 100%;
    height: 280px;
    background-color: #0000001a;
    box-shadow: 0 0 5px #989898;
    border-radius: 5px;
}
.viewHorizontal{
    width: 30%;
    height: 3%;
}
.viewVertical{
    width: 3%;
    height: 30%;
}

.viewHorizontal, .viewVertical{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #cccbcb;
}
.viewPhotoBtn{
    margin-top: 10px;
    outline: none;
    border: 2px solid #C52828;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    color: #C52828;
    font-size: 18px;
    background-color: inherit;
}
.viewPhotoContainer{
    margin-top: 8px;
    width: 100%;
    height: 280px;
    border-radius: 5px;
}

@media only screen and (min-width: 768px){
    .viewContainer{
        padding: 30px 80px;
    }
    .photoViewWrapper{
        width: 450px;
        padding: 20px 50px;
    }
    .viewPhotoInput{
        height: 350px;
    }
    
}
</style>