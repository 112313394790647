<template>
    <div @click="mapEvent" class="locationMapBtnContainer" :class="infoView">
        <div class="locationMapBtnWrapper">
            <img src="../assets/map.png" alt="map icon"/>
        </div>
    </div>
</template>

<script>
import {getObjectCoords} from '../utility/utils';
export default {
    props:{
        infoView: {
            type: String
        },
        objectId: {
            type: String
        }
    },
    setup(props){
        const mapEvent = async() =>{
            const coords = await getObjectCoords(props.objectId);
            //open google maps with coords of object
            window.open(
                "https://www.google.com/maps/search/?api=1&query=" + `${coords.lat},${coords.lon}`,
                "_blank"
            );
        }

        return{mapEvent}
    }
}
</script>

<style>
.locationMapBtnContainer{
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    cursor: pointer;
}
.locationMapBtnWrapper{
    border-radius: 10px;
    background-color: #FF8D2F;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.locationMapBtnContainer img {
    width: 25px;
    height: 25px;
}
.infoView{
    padding: 0;
}
</style>