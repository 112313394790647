<template>
  <div class="licenseContainer">
    <div class="licensesContainer">
        <p class="licenseElement orange">About Weezie Field</p>
        <p class="licenseElement">Licenses</p>
        <p class="licenseElement">Legal</p>
    </div>
    <NavigationBar component="license" />
  </div>
</template>

<script>
import NavigationBar from '../components/NavigationBar';
export default {
    name: 'LicenseView',
    components:{
        NavigationBar
    },
    props:{
        component: {
            type: String
        }
    },
    setup(){
        
    }
}
</script>

<style>
.licenseContainer{
    width: 100;
    height: 100vh;
}
.licensesContainer{
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    padding-top: 20px;
}
.licenseElement{
    font-weight: bold;
    margin-bottom: 20px;
}
.orange{
    color: #FF8D2F;
}
</style>