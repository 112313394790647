import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import UpdateView from '../views/UpdateView.vue';
import InfoView from '../views/InfoView.vue';
import PhotoView from '../views/PhotoView.vue';
import CalendarView from '../views/CalendarView.vue';
import LicenseView from '../views/LicenseView.vue';
import ListView from '../views/ListView.vue';

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/info/:id',
    name: 'info',
    component: InfoView
  },
  {
    path: '/update/:id',
    name: 'update',
    component: UpdateView
  },
  {
    path: '/photo/:id',
    name: 'photo',
    component: PhotoView
  },
  {
    path: '/license',
    name: 'license',
    component: LicenseView
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: CalendarView
  },
  {
    path: '/list',
    name: 'list',
    component: ListView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
