<template>
  <div class="navigationBarContainer">
    <div class="navigationIconContainer">
        <i class="fa-solid fa-map navigationIcon" @click="goToHome" :class="home"/>
    </div>
    <div class="navigationIconContainer">
        <i class="fa-solid fa-list navigationIcon" @click="goToList"/>
    </div>
    <div class="navigationIconContainer">
        <i class="fa-solid fa-list-check navigationIcon" @click="goToCalendar"/>
    </div>
    <div class="navigationIconContainer">
        <i class="fa-solid fa-gear navigationIcon" :class="license" @click="goToLicense"/>
    </div>
  </div>
</template>

<script>
import router from "../router/index";
import { ref, toRef} from 'vue'
export default {
    name: 'NavigationBar',
    props:{
        component: {
            type: String
        }
    },
    setup(props){
        const style = toRef(props, 'component');
        const home = ref(null);
        const license = ref(null);

        const checkComponentStyling=()=>{
            if(style.value === 'home'){
                home.value = 'orange';
            }else{
                license.value = 'orange';
            }
        }
        checkComponentStyling();
        
        const goToHome=()=>{
            router.push({name: 'home'});
        }
        const goToList=()=>{
            router.push({name: 'list'});
        }
        const goToCalendar=()=>{
            router.push({name: 'calendar'});   
        }
        const goToLicense=()=>{
            router.push({name: 'license'});
        }

        return {goToList, goToCalendar, goToLicense, goToHome,home, license}
    }
}
</script>

<style>
.navigationBarContainer{
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 50px;
    background-color: #FFFFFF;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navigationIconContainer{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navigationIcon{
    width: 20px;
    height: 20px;
    color: #989898;
}
.orange{
    color: #FF8D2F;
}

</style>