<template>
  <div class="updateViewContainer">
    <div v-show="showCamera" class="cameraContainer">
      <video class="videoContainer" id="video">Video stream not available.</video>
      <div @click="cameraOff" class="cameraImgContainer">
        <img src="../assets/arrow.png" alt="arrow icon"/>
      </div>
      <button class="cameraBtn" id="startbutton" @click="takePicture"></button>
      <canvas id="canvas">
      </canvas>
    </div>
    <div v-show="!showCamera" class="updateNavbar">
      <div @click="goToInfo(objectId)" class="updateImgContainer">
        <img src="../assets/arrow.png" alt="arrow icon"/>
      </div>
      <div class="infoNameContainer">
        <h3>{{objectName}}</h3>
      </div>
    </div>
    <form v-show="!showCamera" @submit.prevent="handleSubmit" class="updateViewInfoContainer">
      <div class="updateViewInfoWrapper">
        <div class="remainingTasksContainer">
          <div v-if="tasksUncompleted !== 0" class="remainingTasksWrapper">
            <p>{{tasksUncompleted}} TASKS REMAINING</p>
          </div>
          <div v-if="tasksUncompleted === 0" class="remainingTasksWrapper greenTasks">
            <p>All TASKS COMPLETED</p>
          </div>
          <div class="updateViewActivityprogressBar">
            <ve-progress v-if="barPercentage !== 100" :color="'#C42828'" :size="45" :progress="barPercentage" :legend-formatter="myFormatter"/>
            <ve-progress v-if="barPercentage === 100" :color="'#2E7D32'" :size="45" :progress="barPercentage" :legend-formatter="myFormatter"/>
          </div>
        </div>
        <div class="updateViewActivityContainer">
          <div class="updateViewActivityInfoContainer">
            <h3>{{activityTitle}}</h3>
            <div class="updateViewActivityInfo">
              <img src="../assets/calendar.png" alt="calendar icon"/>
              <p>{{activityDate}}</p>
            </div>
          </div>
        </div>
        <div class="updateViewTasksContainer">
          <div v-for="task in checklistArray" :key="task.uuid" class="taskContainer" :class="task.color">
            <div class="taskWrapper">
              <h3 @click="showAll(task)" class="taskTitle">{{task.title}}</h3>
              <div class="taskInfoContainer">
                <p @click="showAll(task)" class="taskDescription" v-if="!task.show">
                  {{task.description}}
                </p>
                <p @click="showAll(task)" class="taskDescription noDots" v-if="task.show">
                  {{task.description}}
                </p>
                <div v-if="task.show" class="checkTaskContainer">
                  <div v-for="item in task.content" :key="item.uuid" class="checkTaskWrapper">
                    <label class="container">
                      <input type="checkbox" :id="item.uuid" :value="`${item.uuid} ${task.uuid}`" v-model="checkboxArray"/>
                      <span @click="showChecked(task.uuid,item.uuid)" v-if="item.value !== 'true'" class="checkmark">
                        <span v-if="item.show" class="after"></span>
                      </span>
                      <span v-if="item.value === 'true'" class="checkmark2"></span>-->
                    </label>
                    <p @click="showAll(task)" class="itemDesContainer">{{item.label}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-for="task in textArray" :key="task.uuid" class="taskContainer" :class="task.color">
            <div class="taskWrapper">
              <h3 @click="showAll(task)" class="taskTitle">{{task.title}}</h3>
              <div class="taskInfoContainer">
                <p @click="showAll(task)" class="taskDescription" v-if="!task.show">
                  {{task.description}}
                </p>
                <p @click="showAll(task)" class="taskDescription noDots" v-if="task.show">
                  {{task.description}}
                </p>
                <div v-if="task.show" class="checkTaskContainer">
                  <div v-for="item in task.content" :key="item.uuid" class="checkTextTaskWrapper">
                    <p class="textTaskLabel">{{item.label}}</p>
                    <input class="textTaskInput" v-if="!item.placeholder" :value="item.value" type="text" @input="event => addText(task.uuid, item.uuid,event.target.value)"/>
                    <p class="textTaskDisabled" v-if="item.placeholder">{{item.value}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-for="task in photoArray" :key="task.uuid" class="taskContainer" :class="task.color">
            <div class="taskWrapper">
              <h3 @click="showAll(task)" class="taskTitle">{{task.title}}</h3>
              <div class="taskInfoContainer">
                <p @click="showAll(task)" class="taskDescription" v-if="!task.show">
                  {{task.description}}
                </p>
                <p @click="showAll(task)" class="taskDescription noDots" v-if="task.show">
                  {{task.description}}
                </p>
                <div v-if="task.show" :id="task.uuid" class="photoTaskContainer">
                  <div v-for="item in task.content" :key="item.uuid" :id="item.uuid" class="photoTaskWrapper">
                    <div v-if="!item.value" class="photoInput" @click="showDialog(task,item.uuid)">
                      <span class="horizontal"/>
                      <span class="vertical"/>
                    </div>
                    <div v-if="item.value && !item.placeholder" class="photoContainer" @click="showDialog(task,item.uuid)">
                      <img class="photo" :src="item.value" alt="">
                    </div>
                    <div v-if="item.placeholder" class="photoContainer">
                      <img class="photo" :src="item.value" alt="">
                    </div>
                    <span v-if="item.showDialog" :class="`${item.direction}Arrow`"/>
                    <div v-if="item.showDialog" :class="`${item.direction}Container`">
                      <div @click="viewPhoto(item.uuid)" class="modalItem border">
                        <img src="../assets/viewPhoto.png" alt="red check icon"/>
                        <p>View</p>
                      </div>
                      <div @click="takePhoto(task.uuid, item.uuid)" class="modalItem border">
                        <img src="../assets/takePhoto.png" alt="red check icon"/>
                        <p id="camera">New</p>
                      </div>
                      <div @click="deletePhoto(task.uuid, item.uuid)" class="modalItem">
                        <img src="../assets/deletePhoto.png" alt="red check icon"/>
                        <p>Delete</p>
                      </div>
                    </div>
                    <p class="photoTaskLabel">{{item.label}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="updateBtnContainer">
        <button v-if="tasksUncompleted !== 0" type="submit" class="updateBtn">Submit</button>
        <button v-if="tasksUncompleted === 0" type="submit" class="updateBtn light" :disabled="true">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import {ref} from 'vue';
import {checkTasksCompleted, checkTaskExists,getTaskToUpdate, getActivity, getTasksOfActivity, getObjectIdOfActivity, getObjectName,getCurrentUser,getToken,checkTaskStatus, updateTask} from '../utility/utils';
import {writeData, deleteItem} from '../utility/utility';
import { useRoute } from "vue-router";
import router from "../router/index";
import { VeProgress } from "vue-ellipse-progress";
export default {
  name: 'UpdateView',
  components: {
    VeProgress
  },
  props:{
    id: {
      type: String
    }
  },
  setup(){
    const route =useRoute();
    const params = route.params;
    const activityId = params.id;
    const objectName = ref(null);
    const objectId = ref(null);
    const tasksUncompleted = ref(null);
    const activityTitle = ref(null);
    const activityDate = ref(null);
    const barPercentage = ref(null);
    const checklistArray = ref([]);
    const textArray = ref([]);
    const photoArray = ref([]);
    const checkboxArray = ref([]);
    const textValuesArray = ref([]);
    let showCamera = ref(false);
    let tasks;
    let token;
    let current;
    let width = window.innerWidth;    // We will scale the photo width to this
    let height = window.innerHeight;     // This will be computed based on the input stream
    let streaming = false;
    let video = null;
    let canvas = null;
    let stream;
    let photoTaskId;
    let photoContentId;
    // format of circle 
    const myFormatter = ({currentValue}) => {
      return `
        <span style="font-size: 13px">${new Intl.NumberFormat("de-DE").format(currentValue)} %</span>
      `; 
    }
    
    const getUserAndToken = async()=>{
      //get id of current user
      current = await getCurrentUser();
      //get user token;
      token = await getToken(current[0].uuid);
    }
    getUserAndToken();

    // get all data required
    const getData= async()=>{ 
      //get object id
      const idObject = await getObjectIdOfActivity(activityId);
      if(idObject){
        objectId.value = idObject;
        //get object name
        const nameObject = await getObjectName(idObject);
        objectName.value = nameObject;
      }
      //get activity data 
      const activityObj = await getActivity(activityId);
      if(activityObj){
        activityTitle.value = activityObj.title;
        activityDate.value = activityObj.due_date === null ? 'No date Provided' : activityObj.due_date;
      }
      //create tasks array of activity
      tasks = await getTasksOfActivity(activityId);
      if(tasks){
        //get completed and uncompleted task(quantity)
        const tasksCompletedAndUncompleted = checkTasksCompleted(tasks);
        tasksUncompleted.value = tasksCompletedAndUncompleted.tasksUncompleted;
        //get percentage of progress bar
        getPercentage(tasksCompletedAndUncompleted.tasksCompleted,tasksCompletedAndUncompleted.tasksUncompleted);
        
        // clear arrays to store new data when submit
        checklistArray.value = [];
        textArray.value = [];
        photoArray.value = [];

        //filter tasks by type
        tasks.forEach((task)=>{
          //set color status.
          //check status of task
          let status = checkTaskStatus(task);
          //set color status
          task.color = status;
          // create new task object with color and show properties
          switch(task.type){
            case 'checklist':
              checklistArray.value.push(task);
              break;
            case 'text':
              textArray.value.push(task);
              break;
            case'photo':          
              photoArray.value.push(task);
              break;
            default: 
              break;
          }
        })
      }
    }
    getData();  

    //calculate percentage of tasks completed
    const getPercentage = (tasksCompleted, tasksUncompleted) =>{
      const total = tasksCompleted + tasksUncompleted;
      const percentage = (tasksCompleted/total)*100;
      barPercentage.value = Math.round(percentage);
    }

    //show description and checklist
    const showAll = async(task) =>{
      let taskCopy;
      // find task and change task show value
      tasks.forEach(item => {
        if(item.uuid === task.uuid){
          if(task.show === false){
            task.show = true;
          }else{
            task.show = false;
          }
          item.show = task.show;
          taskCopy = item;
        }
      });
      //save task on DB
      await updateTask(taskCopy);
      //fetch every text type task
      for(let i = 0; i < textValuesArray.value.length; i++){
        await createTextTask(textValuesArray.value[i]);
      }
    }

    //get data from checkbox
    const showChecked = async(taskId, contentId) =>{
      let taskCopy;
      // find task of checklist and change content show propertie value
      tasks.forEach(task => {
        if(task.uuid === taskId){
          task.content.forEach(item =>{
            if(item.uuid === contentId){
              item.show = !item.show;
            }
          });
          taskCopy = task;
        }
      });
      const{title,description, scheduled_date, uuid, activity, type, content, ...rest} = taskCopy;
      const taskToSubmit = {
        title,
        description,
        scheduled_date,
        uuid,
        activity,
        type,
        content
      }
      // save task on DB
      await updateTask(taskCopy);
      //call fuction to create task object to submit and task object to save on DB
      createTaskToSubmit(taskToSubmit, taskCopy);
    }

    //fuction to create task object to submit and task object to save on DB
    const createTaskToSubmit = async(task, taskCopy) =>{
      //clone task ofbject
      const copyOfTask = {...taskCopy};
      const contentCopy = [];
      const contentToSave = [];

      // create new content array with values updated
      const content = task.content.map(item =>{
        let newObject;
        if(item.show){
          newObject = {
            uuid: item.uuid,
            label: item.label,
            value: 'true',
            options: item.options
          }
        }else{
          newObject = {
            uuid: item.uuid,
            label: item.label,
            value: null,
            options: item.options
          }
        }
        return newObject
      });
      //push content object wich values are 'true' into contentToSave array
      content.forEach(item =>{
        if(item.value === 'true'){
          contentToSave.push(item);
        }
      });
      //create task to be submitted with updated content
      const taskToSubmit = {
        title: task.title,
        description: task.description,
        scheduled_date: task.scheduled_date,
        uuid: task.uuid,
        activity: task.activity,
        type: task.type,
        content: contentToSave,
        taskCopy: copyOfTask
      }
      //create content array for taskCopy
      task.content.forEach(item =>{
        const newObject = {
          show:item.show,
          uuid: item.uuid,
          label: item.label,
          value: item.value,
          options: item.options
        }
        contentCopy.push(newObject);
      });
      copyOfTask.content = contentCopy;

      //update content values of taskCopy
      copyOfTask.content.forEach(taskContent =>{
        contentToSave.forEach(item =>{
          if(taskContent.uuid === item.uuid){
            taskContent.value = 'true';
          }
        })
      });
      await writeData('updateLocations',taskToSubmit);
    }
    //fetch checklist task
    const fetchCheckList = async(task) =>{
      try{
        const {taskCopy, ...rest} = task;
        if(task.content.length > 0){
          const response = await fetch(`${current[0].url}tasks/${rest.uuid}/execute`,{
            method: 'PUT',
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
            body:JSON.stringify(rest)
          });
          const res = await response.json();
          console.log(res);
          if(response.status === 200){
          // count how many content values are true
            let count = 0;
            taskCopy.content.forEach(content =>{
              if(content.value){
                count ++;
              }
            })
            //change task status
            if(count === taskCopy.content.length){
              taskCopy.state = "completed";
            }else if(count === 0){
              taskCopy.state = "scheduled";
            }else{
              taskCopy.state = "in_progress";
            }
            //update task in DB
            await updateTask(taskCopy);
            await deleteItem('updateLocations', rest.uuid);
            // get new data
            getData(); 
          }
          
        }else{
          await deleteItem('updateLocations', rest.uuid);
        }
      }catch(err){
        console.log(err);
      }
    }
    //submit function
    const handleSubmit = async() =>{
      for(let i = 0; i < textValuesArray.value.length; i++){
        await createTextTask(textValuesArray.value[i]);
      }
      const tasks = await getTaskToUpdate();
      for (let i = 0; i < tasks.length; i++){
        switch(tasks[i].type){
          case 'checklist':
            await fetchCheckList(tasks[i]);
            break;
          case 'text':
            await fetchTextList(tasks[i]);
            break
          case 'photo':
            await fetchPhotoList(tasks[i]);
            break
        }
      }
      
    }

    // create object with input values. 
    const addText = (taskId, contentId, text) =>{
      // create task object with task id
      const taskObject = {
        taskId,
        content: []
      };
      // if task object exits in array, create/edit content array, if doesn´t exits, push task object
      if(textValuesArray.value.some(item => item.taskId === taskId)){
        //update content of object
        textValuesArray.value.forEach(item=>{
          if(item.taskId === taskId){
            // check if content object exits in array, if exits, update text value, if not
            //push new content object into content array
            if(item.content.some(content => content.uuid === contentId)){
              item.content.forEach(item =>{
                if(item.uuid === contentId){
                  item.value = text;
                }
              })
            }else{
              const content = {
                uuid: contentId,
                value: text
              }
              item.content.push(content);
            }
          }
        })
      }else{
        // create content object
        const contentObject = {
          uuid: contentId,
          value: text
        }
        // push content object into content array
        taskObject.content.push(contentObject);
        // push task object into array
        textValuesArray.value.push(taskObject);
      }
    }

    const createTextTask = async(task) =>{
      //task to be saved for later update
      let taskCopy;
      // task to be saved on tasks table 
      let taskToSave;
      //task to be submitted
      let taskToSubmit;
      //content of task to be submitted
      const submitContent = [];
      //content of task to be saved on DB when no submit
      const saveContent = [];

      tasks.forEach(object =>{
        if(object.uuid === task.taskId){
          taskCopy = {...object};
          taskToSave = {...object}
          taskToSubmit = {
            title: object.title,
            description: object.description,
            scheduled_date: object.scheduled_date,
            uuid: object.uuid,
            activity_uuid: object.activity,
            type: object.type,
            content: [],
            copyTask: null
          }
        }
      });

      // create content to be submitted to server
      taskCopy.content.forEach(content =>{
        //check if task exits on tasks updated by user
        if(checkTaskExists(task.content, content.uuid)){
          task.content.forEach(item =>{
            //if exits, check if task already exits on taskToSubmit, if exits, update value
            //if doesn´t exits, create task and push inside tasktosubmit content array
            if(content.uuid === item.uuid){
              if(!checkTaskExists(taskToSubmit.content, item.uuid)){
                const copy = {
                  label: content.label,
                  options: content.options,
                  uuid: content.uuid,
                  value: item.value
                }
                taskToSubmit.content.push(copy);
              }else{
                taskToSubmit.content.forEach(element =>{
                  if(item.uuid === element.uuid){
                    element.value = item.value;
                  }
                })
              }
            }
          });
        }else{
          // if task doesn´t exits on array of tasks updated, check if has a value and placeholder is false
          if(content.value && content.placeholder === false){
            //if task already exits in content array to submit, update value
            if(checkTaskExists(taskToSubmit.content, content.uuid)){
              taskToSubmit.content.forEach(item=>{
                if(item.uuid === content.uuid){
                  item.value = content.value;
                }
              })
            }else{
              // if task doesn´t exits, create new task and push inside content array to be submitted
              const copy = {
                label: content.label,
                options: content.options,
                uuid: content.uuid,
                value: content.value
              }
              taskToSubmit.content.push(copy);
            }
          }
        }
      });
      // create content to be saved on submit and when no submit
      taskCopy.content.forEach(item =>{
        const obj = {
          label: item.label,
          placeholder: item.placeholder,
          options: item.options,
          uuid: item.uuid,
          value: item.value
        }
        const obj1 = {...obj};
        submitContent.push(obj);
        saveContent.push(obj1);
      })
      //update value and placeholder of content to be saved on DB in submit
      submitContent.forEach(item =>{
        task.content.forEach(content =>{
          if(item.uuid === content.uuid){
            item.value = content.value
          }
        })
      });
      //update values of content to be saved when no submit
      saveContent.forEach(item =>{
        task.content.forEach(content =>{
          if(item.uuid === content.uuid){
            item.value = content.value
          }
        })
      })
      //create tas to be submitted
      taskCopy.content = submitContent;
      taskToSubmit.copyTask = taskCopy;
      //create task to be saved when no submit
      taskToSave.content = saveContent;
      //save task on tasks table in DB
      await updateTask(taskToSave);
      //save task in updateLocations
      await writeData('updateLocations',taskToSubmit);
      //re initialize array to avoid duplicates;
      taskToSubmit.content = [];
      //re-render component
      getData();
    }

    // fetch text task
    const fetchTextList = async(task) =>{
      try{
        let count = 0;
        //content whitout empty value
        const restContent = [];
        //separate task to submit and task to be saved
        const {copyTask, ...rest} = task;
        //check for empty string in content value to be saved
        copyTask.content.forEach(item =>{
          if(item.value === ''){
            item.value = null;
          }
        });
        //check for empty string in content value to be submitted
        rest.content.forEach(item =>{
          if(item.value !== ''){
            restContent.push(item);
          }
        });
        rest.content = restContent;
        if(rest.content.length > 0){
          // PUT method
          const response = await fetch(`${current[0].url}tasks/${rest.uuid}/execute`,{
            method: 'PUT',
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
            body:JSON.stringify(rest)
          });
          const res = await response.json();
          console.log(res);
          if(response.status === 200){
            copyTask.content.forEach(content =>{
              if(content.value){
                content.placeholder = true;
                count ++;
              }
            })
            //change task status
            if(count === copyTask.content.length){
              copyTask.state = "completed";
            }else if(count === 0){
              copyTask.state = "scheduled";
            }else{
              copyTask.state = "in_progress";
            }
            //update task in DB
            await updateTask(copyTask);
            //delete task from updateLocations table
            await deleteItem('updateLocations', rest.uuid);
            // get new data
            getData();
          }
        }else{
          //delete task from updateLocations table
          await deleteItem('updateLocations', rest.uuid);
        }
      }catch(err){
      }
    }

    //submit photo task
    const fetchPhotoList = async(task) =>{
      let count = 0;
      //content whitout empty value
      const restContent = [];
      //separate task to submit and task to be saved
      const {copyTask, ...rest} = task;
      //check for empty string in content value to be submitted
      //create new content to submit
      rest.content.forEach(item =>{
        if(item.value !== null){
          const obj = {
            label: item.label,
            options: item.options,
            uuid: item.uuid,
            value: item.value.slice(22)
          }
          restContent.push(obj);
        }
      });
      rest.content = restContent;
      if(rest.content.length > 0){
        // PUT method
        const response = await fetch(`${current[0].url}tasks/${rest.uuid}/execute`,{
          method: 'PUT',
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
          body:JSON.stringify(rest)
        });
        const res = await response.json();
        console.log(res);
        if(response.status === 200){
          copyTask.content.forEach(content =>{
            if(content.value){
              content.placeholder = true;
              content.showDialog = false;
              count ++;
            }
          })
          //change task status
          if(count === copyTask.content.length){
            copyTask.state = "completed";
          }else if(count === 0){
            copyTask.state = "scheduled";
          }else{
            copyTask.state = "in_progress";
          }
          //update task in DB
          await updateTask(copyTask);
          //delete task from updateLocations table
          await deleteItem('updateLocations', rest.uuid);
          // get new data
          getData();
          
        }
      }else{
        //delete task from updateLocations table
        await deleteItem('updateLocations', rest.uuid);
      }
    }

    // turn camera on and display video on screen
    const takePhoto = async(taskUuid, contentUuid)=>{
      try{
        showCamera.value = !showCamera.value
        width = window.innerWidth;    // We will scale the photo width to this
        height = window.innerHeight;     // This will be computed based on the input stream
        streaming = false;
        video = null;
        canvas = null;
        //did this to avoid multiples executions with addEventListener on btn
        photoTaskId = taskUuid
        photoContentId = contentUuid;
        
        video = document.getElementById('video');
        canvas = document.getElementById('canvas');

        stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false })
        video.srcObject = stream;
        video.play();

        video.addEventListener('canplay', (ev)=>{
          if (!streaming) {
            //height = video.videoHeight / (video.videoWidth/width);
            video.setAttribute('width', width);
            video.setAttribute('height', height);
            canvas.setAttribute('width', width);
            canvas.setAttribute('height', height);
            streaming = true;
          }
        }, false);
      }catch(err){
      }
    }
    
    // take photo and converts it to png
    const takePicture = async()=>{
      const context = canvas.getContext('2d');
      if (width && height) {
        canvas.width = width;
        canvas.height = height;
        context.drawImage(video, 0, 0, width, height);
        const data = canvas.toDataURL('image/png');
        const tracks = stream.getTracks();
        tracks.forEach((track)=>{
          track.stop();
        });
        //get task from task array and passed it into function to create task to save on db and to be submitted
        await createphotoTask(photoTaskId, photoContentId, data);
        showCamera.value = !showCamera.value
      }
    }

    const createphotoTask = async(taskId, contentId, data) =>{
      //task to be saved for later update
      let taskCopy;
      // task to be saved on tasks table 
      let taskToSave;
      //task to be submitted
      let taskToSubmit;
      //content of task to be submitted
      const submitContent = [];
      //content of task to be saved on DB when no submit
      const saveContent = [];

      tasks.forEach(object =>{
        if(object.uuid === taskId){
          taskCopy = {...object};
          taskToSave = {...object}
          taskToSubmit = {
            title: object.title,
            description: object.description,
            scheduled_date: object.scheduled_date,
            uuid: object.uuid,
            activity_uuid: object.activity,
            type: object.type,
            content: [],
            copyTask: null
          }
        }
      });

      taskCopy.content.forEach(item=>{
        if(item.uuid === contentId){
          const obj = {
            showDialog: item.showDialog, 
            direction: item.direction,
            label: item.label,
            placeholder: item.placeholder,
            options: {
              file_type: "png"
            },
            uuid: item.uuid,
            value: data
          }
          taskToSubmit.content.push(obj);
        }else{
          if(item.value && item.placeholder === false){
            const obj = {
              showDialog: item.showDialog, 
              direction: item.direction,
              label: item.label,
              placeholder: item.placeholder,
              options: {
                file_type: "png"
              },
              uuid: item.uuid,
              value: item.value
            }
            taskToSubmit.content.push(obj);
          }
        }
      });

      // create content to be saved on submit and when no submit
      taskCopy.content.forEach(item =>{
        const obj = {
          showDialog: item.showDialog, 
          direction: item.direction,
          label: item.label,
          placeholder: item.placeholder,
          options: item.options,
          uuid: item.uuid,
          value: item.value
        }
        const obj1 = {...obj};
        submitContent.push(obj);
        saveContent.push(obj1);
      })
      //update value and placeholder of content to be saved on DB in submit
      submitContent.forEach(item =>{
        if(item.uuid === contentId){
          item.value = data;
        }
      });
      //update values of content to be saved when no submit
      saveContent.forEach(item =>{
        if(item.uuid === contentId){
          item.value = data;
        }
      })
      //create tas to be submitted
      taskCopy.content = submitContent;
      taskToSubmit.copyTask = taskCopy;
      //create task to be saved when no submit
      taskToSave.content = saveContent;
      //save task on tasks table in DB
      await updateTask(taskToSave);
      //save task in updateLocations
      await writeData('updateLocations',taskToSubmit);
      //re-render component
      getData();

    }

    //send to photo view
    const viewPhoto = (id) =>{
      router.push({ name: 'photo', params: {id}});
    }

    //delete photo of DB
    const deletePhoto = async(taskId, contentId) =>{
      // update task of updateLocations table
      await updatePhotoToSubmit(taskId, contentId);
      // update task of tasks table
      await updatePhotoToSave(taskId, contentId);
    }

    //update task of updateLocations
    const updatePhotoToSubmit = async(taskId, contentId) =>{
      //update content to be submited
      let taskObj;
      const tasksArray = await getTaskToUpdate();
      tasksArray.forEach(task =>{
        if(task.uuid === taskId){
          taskObj = {...task}
        }
      });
      if(taskObj){
        // update content to be submitted
        taskObj.content.forEach(item =>{
          if(item.uuid === contentId){
            item.value = null;
          }
        });
        if(taskObj.copyTask){
          //update content to be saved in tasks table when submit
          taskObj.copyTask.content.forEach(item =>{
            if(item.uuid === contentId){
              item.value = null;
            }
          })
        }
        //save task in updateLocations
        await writeData('updateLocations',taskObj);
      }
    }

    //update task of tasks table
    const updatePhotoToSave = async(taskId, contentId) =>{
      let taskCopy;
      tasks.forEach(task =>{
        if(task.uuid === taskId){
          taskCopy = {...task}
        }
      });
      taskCopy.content.forEach(item =>{
        if(item.uuid === contentId && item.value){
          item.value = null;
        }
      })
      //save task on tasks table in DB
      await updateTask(taskCopy);
      getData();
    }

    //show dialog of photo
    const showDialog=(task, id)=>{
      let dir;
      //get photo task Wrapper position
      const div = document.getElementById(id);
      const divPos = div.offsetLeft;
      //get parent div position
      const parentDiv = document.getElementById(task.uuid);
      const width = parentDiv.offsetWidth;

      //need at least 85px to not cause overflow
      // if more than 85px, create dialog on the right side, else, create in the left side
      const total = width - divPos;
      if(total > 85){
        dir = 'right';
      }else{
        dir = 'left';
      }
      // find task and change task show value and set right or left dialog
      photoArray.value.forEach(item => {
        if(item.uuid === task.uuid){
          item.content.forEach(content =>{
            if(content.uuid === id){
              content.showDialog = !content.showDialog;
              content.direction = dir;
            }
          })
        }
      });
    }

    const cameraOff = ()=>{
      const tracks = stream.getTracks();
        tracks.forEach((track)=>{
          track.stop();
        });
      showCamera.value = !showCamera.value
    }

    const goToInfo = async(id) =>{
      
      for(let i = 0; i < textValuesArray.value.length; i++){
        await createTextTask(textValuesArray.value[i]);
      }
      
      router.push({ name: 'info', params: {id}});
    }

    return{showChecked, viewPhoto, showCamera, cameraOff,takePicture, deletePhoto, addText, showDialog, takePhoto, showAll, checkboxArray, objectId, handleSubmit, objectName, tasksUncompleted, goToInfo, activityTitle, activityDate,barPercentage, myFormatter, checklistArray, photoArray, textArray}
  }
}
</script>

<style>
#canvas {
  display:none;
}
#photo {
  display:none;
}
.updateViewContainer{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  background-color: #F4F5F4;
}
.cameraContainer{
  position: relative;
  width: 100%;
  height: 100%;
}
.cameraImgContainer{
  height: 20px;
  width: 20;
  position: absolute;
  top: 10px;
  left: 10px;
}
.cameraImgContainer img{
  width: 100%;
  height: 100%;
}
.videoContainer{
  object-fit: cover;
}
.cameraBtn{
  width: 50px;
  height: 50px;
  position: absolute;
  top: calc(100% - 60px);
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  border: none;
  border-radius: 25px;
}
 
.updateNavbar{
  display: flex;
  width: 100%;
  height: 70px;
  background-color: #FF8D2F;
  position: sticky;
  top: 0;
  z-index: 999;
}
.updateImgContainer{
  top: calc(35px - 10px);
  left: 25px;
  height: 20px;
  width: 28px;
  cursor: pointer;
  position: absolute;
}
.updateImgContainer img {
  height: 100%;
  width: 100%;
}
.updateViewActivityContainer{
  display: flex;
  padding: 0px 25px;
}
.updateViewActivityInfoContainer{
  flex-direction: column;
  justify-content: space-between;
  overflow-wrap: break-word;
}
.updateViewActivityInfo{
  display: flex;
  align-items: flex-end;
}
.updateViewActivityInfo p {
  color: #989898;
  font-size: 15px;
}
.updateViewActivityInfo img{
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
.updateViewActivityprogressBar{
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.remainingTasksContainer{
  display: flex;
  margin: 10px 0px 5px 0px;
  padding: 0px 25px;
}
.remainingTasksWrapper{
  padding: 5px 0px;
  color: #D8696E;
  width: 70%;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 10px;
}
.remainingTasksWrapper p{
  text-align: center;
  width: 155px;
  height: 25px;
  padding: 5px;
  border-radius: 25px;
  height: 25px;
  background-color: #f6c9d5;
}
.greenTasks p{
  background-color: #C3F2DF;
  color: #6AAB78;
}
.updateViewTasksContainer{
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  margin-top: 10px;
}
.updateViewInfoContainer{
  width: 100%;
  height: calc(100vh - 70px);
}
.updateViewInfoWrapper{
  width: 100%;
  height: calc(100% - 60px);
  overflow: auto;
}
.updateBtnContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 10px 25px;
  background-color: #F4F5F4;
}
.updateBtn{
  font-size: 16px;
  color: white;
  background-color: #FF8D2F;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
}
.light{
  opacity: 0.7;
}
.taskContainer{
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 2px 5px #989898;
  margin-top: 10px;
  padding-left: 5px;
  font-family: 'Montserrat', sans-serif;
}
.taskWrapper{
  background-color: #FFFFFF;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}
.taskInfoContainer{
  font-size: 14px;
  padding: 0px 8px;
}
.taskDescription{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 2.5px;
  margin-bottom: 10px;
  overflow-wrap: break-word;
  font-size: 11px;
}
.checkTaskContainer{
  width: 100%;
}
.checkTaskWrapper{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.checkTextTaskWrapper{
  color: black;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  overflow-wrap: break-word;
}
.photoTaskContainer{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
/* Avoids weird space on uncompleted rows  */
.photoTaskContainer::after {
  content: "";
  margin: 0px 5px 5px 5px;
  width: 75px;
}
.photoTaskWrapper{
  position: relative;
  color: black;
  margin: 0px 5px 5px 5px;
}
.photoInput{
  position: relative;
  width: 75px;
  height: 75px;
  background-color: #0000001A;
  box-shadow: 0px 0px 5px #989898;
  border-radius: 5px;
}
.photoContainer{
  position: relative;
  width: 75px;
  height: 75px;
}
.photo{
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.horizontal, .vertical{
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #CCCBCB;
}
.horizontal{
  width: 30%;
  height: 3%;
}
.vertical{
  width: 3%;
  height: 30%;
}
.rightContainer{
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 100px;
  position: absolute;
  bottom: 0px;
  right: -90px;
  background-color: #4d4d4d;
  padding: 0px 5px;
  z-index: 999;
  border-radius: 5px;
}
.rightArrow{
  position: absolute;
  width: 0;
  height: 0;
  bottom: 50px;
  right: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #4d4d4d;
  z-index: 998;
  background-color: inherit;
}
.leftContainer{
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 100px;
  position: absolute;
  bottom: 0px;
  left: -90px;
  background-color: #4d4d4d;
  padding: 0px 5px;
  z-index: 999;
  border-radius: 5px;
}
.leftArrow{
  position: absolute;
  width: 0;
  height: 0;
  bottom: 50px;
  left: -10px;
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #4d4d4d;
  z-index: 998;
  background-color: inherit;
}
.modalItem{
  flex: 1;
  display: flex;
  align-items: center;
  color: white;
  font-size: 13px;
  padding-left: 3px;
}
.border{
  border-bottom: 1px solid white;
}
.modalItem img {
  width: 17px;
  height: 17px;
  margin-right: 4px;
}

.photoTaskLabel{
  margin-top: 2px;
  text-align: center;
}
.itemDesContainer{
  width: calc(100% - 30px);
  overflow-wrap: break-word;
  padding-left: 20px;
}
.noDots{
  white-space: inherit;
  text-overflow: inherit;
}
.taskTitle{
  margin: 7px 0px;
  font-size: 16px;
  overflow-wrap: break-word;
}
.redStatus{
  background-color: #C42828;
}
.yellowStatus{
  background-color: #f7c22f;
}
.greenStatus{
  background-color: #2E7D32;
}
/* custom checkboxs */
/* The container */
.container{
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark, .checkmark2{
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  border-radius: 5px;
  background-color: #F4F5F4;
  box-shadow: 0px 2px 5px #989898;
}

/* Create the checkmark/indicator (hidden when not checked) 
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
*/
.checkmark2:after{
  content: "";
  position: absolute;
  display: block;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.container .checkmark2:after {
  left: 10px;
  top: 0px;
  width: 9px;
  height: 18px;
  border: solid #2E7D32;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.after{
  content: "";
  position: absolute;
  left: 10px;
  top: 0px;
  width: 9px;
  height: 18px;
  border: solid #2E7D32;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.textTaskLabel{
  font-size: 10px;
  color: #000000e0;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.textTaskInput{
  outline: none;
  border: none;
  background-color: white;
  padding-bottom: 5px;
}
.textTaskDisabled{
  color: black;
  font-size: 12px;
  padding-bottom: 5px;
}

@media only screen and (min-width: 768px){
    .updateImgContainer{
      left: 70px;
    }
    .remainingTasksContainer{
      padding: 0px 70px;
    }
    .updateViewActivityContainer{
      padding: 0px 70px;
    }
    .updateViewTasksContainer{
      padding: 0px 70px;
    }
    .updateBtnContainer{
      padding-left: 70px;
      padding-right: 70px;
    }
    
}
</style>