import {openDB} from 'idb';

const dbPromise = openDB('post-store', 1,{
    upgrade(db){
        if(!db.objectStoreNames.contains('objects') && !db.objectStoreNames.contains('activities') && !db.objectStoreNames.contains('tasks') && !db.objectStoreNames.contains('locationName') && !db.objectStoreNames.contains('updateLocations') && !db.objectStoreNames.contains('user') && !db.objectStoreNames.contains('currentUser') && !db.objectStoreNames.contains('timestamp')){
            db.createObjectStore('objects',{
                // The 'id' property of the object will be the key.
                keyPath: 'uuid'
            });
            db.createObjectStore('activities',{
                // The 'id' property of the object will be the key.
                keyPath: 'uuid'
            });
            db.createObjectStore('tasks',{
                // The 'id' property of the object will be the key.
                keyPath: 'uuid'
            });
            db.createObjectStore('locationName',{
                // The 'id' property of the object will be the key.
                keyPath: 'uuid'
            });
            db.createObjectStore('updateLocations',{
                // The 'id' property of the object will be the key.
                keyPath: 'uuid'
            });
            db.createObjectStore('user',{
                // The 'id' property of the object will be the key.
                keyPath: 'imei'
            });
            db.createObjectStore('currentUser',{
                // The 'id' property of the object will be the key.
                keyPath: 'uuid'
            });
            db.createObjectStore('timestamp',{
                // The 'id' property of the object will be the key.
                keyPath: 'id'
            });
            /*
            db.createObjectStore('url',{
                // The 'id' property of the object will be the key.
                keyPath: 'id'
            });
            */
        }
    }
});

export const writeData=(st,data)=>{
    return dbPromise
        .then(async(db)=>{
                const tx = db.transaction(st, 'readwrite');
                const store = tx.objectStore(st);
                await store.put(data);
                await tx.done;
                return true;
        })
        .catch((err)=>{
            console.log(err);
        })
}

export const addData=(st,data)=>{
    return dbPromise
        .then(async(db)=>{
                const tx = db.transaction(st, 'readwrite');
                const store = tx.objectStore(st);
                await store.add(data);
                await tx.done;
        })
        .catch((err)=>{
            console.log(err);
        })
}

export const readAllData=(st)=>{
    return dbPromise
        .then(async(db)=>{
            const tx = db.transaction(st, 'readonly');
            const store = tx.objectStore(st);
            return store.getAll();
        })
        .catch((err)=>{
            console.log(err);
        })
}

export const clearAllData=(st)=>{
    return dbPromise
        .then(async(db)=>{
            const tx = db.transaction(st, 'readwrite');
            const store = tx.objectStore(st);
            await store.clear();
            await tx.done;
        })
        .catch((err)=>{
            console.log(err);
        })

}
export const deleteItem=(st,id)=>{
    return dbPromise
        .then(async(db)=>{
            const tx = db.transaction(st, 'readwrite');
            const store = tx.objectStore(st);
            await store.delete(id);
            await tx.done;
        })
        .catch((err)=>{
            console.log(err);
        })
}

export const trimCache=(cacheName, maxItems)=>{
    caches.open(cacheName)
        .then((cache)=>{
            return cache.keys()
            .then((keys)=>{
                if(keys.length > maxItems){
                    cache.delete(keys[0])
                        .then(
                            trimCache(cacheName, maxItems)
                        ).catch((err)=>{
                        })
                }
            })
            .catch((err)=>{
            })
        })
        .catch((err)=>{
        })
}

/*
const dbPromise = openDB('post-store', 1,{
    upgrade(db){
        console.log(!db.objectStoreNames.contains('post'));
        if(!db.objectStoreNames.contains('posts')){
            db.createObjectStore('post',{
                // The 'id' property of the object will be the key.
                keyPath: '_id'
            })
        }
    }
});
*/

/*
    dbPromise
        .then(async function(db) {
            data.forEach(async(key)=>{
                console.log(key)
                const tx = db.transaction('post', 'readwrite');
                const store = tx.objectStore('post');
                await store.put(key);
                await tx.done;
            });
        })
        .catch((err)=>{
            console.log(err);
        })
*/