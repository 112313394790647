<template>
    <div class="carousel__item">
        <div class="locationContainer">
            <div class="locationInfoContainer">
                <div class="locationObjectContainer">
                    <h3>{{objectName}}</h3>
                    <p><img class="tasksIcon" src="../assets/marker.png" alt="calendar icon"/>{{streetName}}</p>
                </div>
                <div class="locationTasksActivitiesContainer">
                    <div class="locationActivitiesContainer">
                        <div class="locationImgContainer">
                            <img src="../assets/doubleCheck.png" alt="double icon"/>
                            <img src="../assets/tasksCompleted.png" alt="green check icon"/>
                        </div>
                        <div class="locationTextContainer">
                            <p>{{activitiesCompleted}} activities completed</p>
                            <p>{{tasksCompleted}} tasks completed</p>
                        </div>
                    </div>
                    <div class="locationTasksContainer">
                        <div class="locationImgContainer">
                            <img src="../assets/redCheckActivities.png" alt="red check icon"/>
                            <img src="../assets/tasksUncompleted.png" alt="red check icon"/>
                        </div>
                        <div class="locationTextContainer">
                            <p>{{activitiesUncompleted}} activities to be completed</p>
                            <p>{{tasksUncompleted}} tasks to be completed</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="locationButtonContainer">
                <div @click="goToInfo" class="locationLinkBtnContainer">
                    <p>Open</p>
                </div>
                <MapButton :objectId = "objectId"/>
            </div>
        </div>
    </div>
</template>

<script>
import { toRefs, ref } from 'vue';
import {getActivitiesAndTasks, checkTasksCompleted, checkActivityCompleted, getStreetName, getObjectName, getObjectCoords, storeStreetName} from '../utility/utils';
import router from "../router/index";
import MapButton from './MapButton.vue';
export default {
    name: 'LocInfo',
    components: { 
        MapButton
    },
    props: {
        object: Object,
        imei: String
    },
    setup(props){
        const {object} = toRefs(props);
        const objectId = object.value.uuid;
        const objectName = ref(null);
        const tasksCompleted = ref(null);
        const tasksUncompleted = ref(null);
        const activitiesCompleted = ref(0);
        const activitiesUncompleted = ref(0);
        const streetName = ref(null);

        const getTasksAndActivities = async() =>{
            //get street name of object
            const street = await getStreetName(objectId);
            if(street){
                streetName.value = street;
            }else{
                const coords = await getObjectCoords(objectId);
                await getObjectLocationName(objectId,coords.lon, coords.lat);
                
            }

            //get object label
            const label = await getObjectName(objectId);
            if(label){
                objectName.value = label;
            }

            const filteredTaskAndActivities = await getActivitiesAndTasks(object.value.uuid);
            if(filteredTaskAndActivities){
                //get object with completed and uncompleted activities
                const activities = filteredTaskAndActivities.filteredActivities;
                for(let i = 0; i < activities.length; i++){
                    const completed = await checkActivityCompleted(activities[i].uuid);
                    if(completed){
                        activitiesCompleted.value++
                    }else{
                        activitiesUncompleted.value++
                    }   
                }

                //get object with completed and uncompleted tasks
                const tasks = checkTasksCompleted(filteredTaskAndActivities.filteredTasks);

                //set activities and tasks values
                tasksCompleted.value = tasks.tasksCompleted;
                tasksUncompleted.value = tasks.tasksUncompleted;
            }

        }
        getTasksAndActivities();

        // get street name of object and stores it on indexedDB
        const getObjectLocationName = async(objectUuid, lon, lat) =>{
            try{
                const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`);
                const res = await response.json();
                if(res){
                    await storeStreetName(objectUuid, res.address.road);
                    streetName.value = res.address.road;
                }
                
            }catch(err){
                console.log(err);
            }
        }

        const goToInfo = () =>{
            router.push({ name: 'info', params: {id: objectId} })
        }

        return {tasksCompleted,objectId, tasksUncompleted, activitiesCompleted, activitiesUncompleted, goToInfo, streetName, objectName}
    }
}
</script>

<style>
.carousel__item {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.locationContainer{
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    border-radius: 5px;
}
.locationInfoContainer{
    width: 100%;
    height: 65%;
}
.locationObjectContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 8px;
    padding-top: 8px;
}
.locationObjectContainer p{
    font-size: 11px;
    color: #989898;
}

.locationTasksActivitiesContainer{
    width: 100%;
    display: flex;
    font-size: 10px;
    margin-top: 2px;
    padding-left: 8px;
}
.locationTasksActivitiesContainer p{
    margin-top: 3px;
}
.locationActivitiesContainer, .locationTasksContainer{
    width: 45%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.locationTasksContainer{
    width: 55%;
}
.locationImgContainer{
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.locationImgContainer img{
    width: 14px;
    height: 14px;
    margin-top: 3px;
}
.locationTextContainer{
    flex: 4;
    text-align: start;
}
.locationButtonContainer{
    width: 100%;
    height: 45%;
    display: flex;
    align-items: center;
    padding: 8px 0px 8px 8px;
}
.locationLinkBtnContainer{
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #FF8D2F;
    border-radius: 10px;
    cursor: pointer;
}
.locationLinkBtnContainer p {
    font-size: 15px;
}

@media only screen and (min-width: 768px){
    .locationObjectContainer h3{
        font-size: 20px;
    }
    .locationObjectContainer p {
        font-size: 14px;
    }
    .locationTasksActivitiesContainer {
        font-size: 12px;
    }
    .locationLinkBtnContainer p {
        font-size: 17px;
    }
}

@media only screen and (min-width: 1024px){
    .locationObjectContainer p {
        font-size: 16px;
    }
    .locationTasksActivitiesContainer {
        font-size: 14px;
    }
    .locationLinkBtnContainer p {
        font-size: 19px;
    }
}
</style>